import template from './tickets.html';
import style from './tickets.less';
import seatsTemplate from './seats/seats.html';

export default angular.module('eventix.shop.tickets', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.tickets', {
            url: '/tickets?eventId',
            views: {
                'shop': {
                    controller: 'TicketsController as vm',
                    templateUrl: template
                }
            },
            params: {
                isCamping: false,
                isShuttle: false
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('TicketsController', function(UIMessages, shop, order, collapses, $state,
        $scope, $q, $http, $translate, Locales, Currencies, Ticket, $interval, $sce, $uibModal,
        $analytics, FB, $timeout, store, $filter, $location, SeatsIO, ShopAnalytics) {
        var vm = this;
        vm.seatSelection = {
            templateUrl: seatsTemplate,
            open: false
        };

        // init
        window.scrollTo(0, 120);

        vm.order = order;
        vm.messages = UIMessages;
        vm.locales = Locales;
        vm.currencies = Currencies;
        vm.shop = shop;
        vm.tickets = order.tickets;
        vm.events = order.events;
        vm.products = order.products;
        vm.collapses = collapses;
        vm.eventFilter = $state.params.eventId;
        vm.isCamping = false;
        vm.isShuttle = false;
        if ($state.params.isCamping)
            vm.isCamping = $state.params.isCamping;
        if ($state.params.isShuttle)
            vm.isShuttle = $state.params.isShuttle;
        vm.atWeekendTickets = false;

        let queryParams = $location.search();
        if (queryParams.coupon)
            vm.order.applyCoupon(queryParams.coupon);

        /** Iterate over events/tickets and check availability */
        vm.availableTickets = order.recalculateAvailableTickets();
        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));

        if(vm.fourStepWizard && !vm.eventFilter)
            $state.go('eventix.shop.events', { shopId: vm.shop.guid });

        if(vm.fourStepWizard && vm.eventFilter)
            ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
        else if(!vm.fourStepWizard)
            ShopAnalytics.impression();

        /**
         * Close seat selection dialog
         */
        vm.closeSeatSelection = function() {
            vm.seatSelection.open = false;
        };

        vm.openSeatSelection = function(ticket, eventId) {
            SeatsIO.open(ticket, eventId).then(() => vm.seatSelection.open = true);
        };

        vm.getDeparture = (title) => {
            return title.split('-')[0];
        };

        vm.getDestination = (title) => {
            return title.split('-')[1];
        };

        vm.openCo2Modal = () => {
            $uibModal.open({
                template: `
                    <div class="co2-modal">
                        <div class="co2-modal__title">
                            <h2>What happens with this donation?</h2>
                        </div>
                        <div class="co2-modal__body helvetica">
                            These generic amounts have been calculated via <a href="https://www.atmosfair.de/en/" target="_blank">Atmosfair</a>, a non-profit organization that invests in CO₂ compensation through various renewable energy projects in more than 15 countries worldwide. Your donation will be used to start and extend such projects in the global south, such as rural electrification with solar power and mini-grids in Madagascar, small biogas plants for farmers in Nepal or efficient cookstoves in Rwanda. Read more about their projects <a href="https://www.atmosfair.de/en/climate-protection-projects/" target="_blank">here</a>, and find out more about why we compensate CO₂ via this organization <a href="https://www.dekmantelfestival.com/en/co2-compensation/" target="_blank">here</a>.
                        </div>
                    </div>
                `
            });
        };

        /** Goto next page in shop if user has selected 1+ ticket */
        vm.next = function() {
            $state.go('eventix.shop.shuttle', { shopId: shop.guid });
        };

        vm.toTickets = function(){
            vm.isCamping = false;
            vm.isShuttle = false;
        }
        vm.toCamping = function(){
            vm.isCamping = true;
            vm.isShuttle = false;
        }
        vm.toShuttle = function(){
            vm.isCamping = false;
            vm.isShuttle = true;
        }

        vm.reserve = function(ticketGuid, eventId){
            if (vm.reachedMaxReservations()) {
                UIMessages.push("A max of 6 tickets per order is allowed");
                return;
            }

            vm.order.reserveTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.addToCart(ticket);
        };

        vm.release = function(ticketGuid, eventId){
            vm.order.releaseTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.removeFromCart(ticket);
        };

        vm.scrollToSingleDay = () => {
            vm.atWeekendTickets = false;
            document.getElementById('single-day-tickets').scrollIntoView();
        };

        vm.scrollToWeekend = () => {
            vm.atWeekendTickets = true;
            document.getElementById('weekend-tickets').scrollIntoView();
        };

        vm.reachedMaxReservations = () => {
            let totalReserved = 0;
            for (const ticketGuid in vm.order.reserveQueue)
                totalReserved += vm.order.reserveQueue[ticketGuid].length;

            for (const ticketGuid in vm.order.reservations)
                totalReserved += vm.order.reservations[ticketGuid].length;

            return totalReserved >= 6;
        };
    }).name;
