import header from './header.html';
import Download from './download/download';
import template from './order.html';
import style from './order.less';

export default angular.module('eventix.order',[ Download])
    .config(function($stateProvider, GoogleTagManagerProvider) {
        $stateProvider.state('eventix.order', {
            url: '/status/:order_guid/:status',
            params: {
                status: { // To support links without the status in the url
                    value: null,
                    squash: true
                }
            },
            views: {
                main: {
                    controller: 'OrderStatusController',
                    controllerAs: 'vm',
                    templateUrl: template
                },
                header: {
                    controller: 'OrderStatusHeaderController',
                    controllerAs: 'vm',
                    templateUrl: header
                }
            },
            resolve: /*@ngInject*/{
                order: function(Order, $transition$) {
                    return Order.getPublic($transition$.params().order_guid).then(order => {
                    // Load Tag as early as possisble
                        if(order.shop.google_tag)
                            GoogleTagManagerProvider.addContainer(order.shop.google_tag);
                        return order;
                    });
                }
            }
        });
    })
    .controller('OrderStatusController', function($state, order, $analytics, Locales, $translate, FB, UIMessages, $q, $scope, $uibModal, ShopAnalytics) {
        var vm = this;
        vm.locales = Locales;
        vm.disableFB = FB.disabled;
        vm.setLocale = code => {
            $translate.use(code);
        };
        vm.shopUrl = SHOP_ROOT + '/' + order.shop_id;

        vm.order = order;
        vm.ticketDownloadExpired = moment().diff(moment(vm.order.created_at, 'YYYY-MM-DDTHH:mm:ssZ'), 'minutes') > 15;
        vm.shop = order.shop;
        vm.supportValues = {
            email: $scope.branding.email || 'tickets@eventix.nl'
        };

        vm.totalPaid = _.reduce(order.payments, (total, p) => p.status === 'paid' ? total + p.amount : total, 0);
        vm.payment = _.first(order.payments);
        vm.currency = _.get(vm.payment, 'currency', DEFAULT_CURRENCY);
        vm.paid = _.get(vm.payment,'status') === 'paid' ? true : false;

        ShopAnalytics.conversion(order);
        vm.facebook = {
            ids: _.uniq(_.compact(_.flatMap(order.tickets, orderedTicket => {
                return _.flatMap(orderedTicket.products, orderedProduct => {
                    return _.map(orderedProduct.product.event_dates, 'facebook_event_id');
                });
            }))),
            busy: false,
            attending: false,
            error: false,
            autoAttend: !!vm.shop.facebook_auto_attend,
            toggleAttend: () => {
                let method = vm.facebook.attending ? 'unattend' : 'attend';
                vm.facebook.busy = true;
                FB[method](vm.facebook.ids).then(() => {
                    vm.facebook.busy = false;
                    vm.facebook.attending = !vm.facebook.attending;
                }, (error) => {
                    vm.facebook.error = true;
                    if(error && error.message)
                        UIMessages.push(error.message);
                    else
                        UIMessages.push('common.notice.error');
                    vm.facebook.busy = false;
                });
            }
        };

        if(vm.shop.facebook_auto_attend) {
            vm.facebook.busy =  true;
            FB.autoAttend(vm.facebook.ids).then(() => {
                vm.facebook.busy = false;
                vm.facebook.attending = true;
            }, () => vm.facebook.busy = false);
        }
    }).controller('OrderStatusHeaderController', function($state, order, $analytics, Locales, $translate) {
        var vm = this;
        vm.locales = Locales;
        vm.setLocale = code => {
            $translate.use(code);
        };
        vm.state = $state;
        vm.shopUrl = SHOP_ROOT + '/' + order.shop_id;
        vm.order = order;
        vm.statusIcon = () => {
            switch(order.status) {
                case 'pending':
                    return 'fa fa-clock-o';
                case 'cancelled':
                    return 'fa fa-times';
                case 'paid':
                default:
                    return $state.includes('eventix.order.download') ? 'fa fa-download' : 'fa fa-check';
            }
        };
        vm.statusText = () => {
            switch(order.status) {
                case 'paid':
                    return $state.includes('eventix.download') ? 'common.shop.order.download' : 'common.shop.order.paid';
                default:
                    return 'common.shop.order.' + order.status;
            }
        };
    }).filter('isAutomaticProduct', function() {
        return function(input) {
            if(!_.isObject(input))
                return false;
            return /^(Access for|Product for)/.test(_.get(input, 'name', ''));
        };
    }).name;
