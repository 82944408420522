import template from './header.html';
import style from './header.less';

export default angular.module('eventix.shop.header',[])
    .controller('HeaderController', function($scope, $state, Currencies, Locales, order, $transitions, $translate, shop, UIMessages, $analytics) {
        var vm = this;
        vm.order = order;
        vm.locales = Locales;
        vm.shop = shop;
        order.recalculateAvailableTickets();
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' ||
            (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        vm.events = _.values(order.events);
        vm.currencies = Currencies;
        vm.state = $state.current.name;

        vm.informTimeout = function() {
            let now = moment();
            let countdownTo = moment(vm.order.firstReservation.expiration, 'YYYY-MM-DDTHH:mm:ssZ');
            let diff = countdownTo.diff(now, 'seconds');
            let timer = [
                Math.max(0,Math.floor(diff / 60)),
                ('0' + Math.max(diff % 60, 0)).substr(-2)
            ].join(':');

            let message = [$translate.instant('common.shop.reservationTimer'), timer].join(' ');
            UIMessages.push(message);
        };

        let deregister = $transitions.onSuccess({}, function(trans){
            let toState = trans.to();
            vm.state = toState.name;
            angular.element('body')[0].scrollTop = 0;
        });
        $scope.$on('$destroy', deregister);
        $scope.$on('reservation_expired', function(ev) {
            vm.expired = true;
            order.releaseAll();
        });
        $scope.$on('stripe_payment_failed', function(ev, reason) {
            vm.stripePaymentFailed = reason;
            order.releaseAll();
        });

        vm.resetShop = function() {
            $state.go('eventix.shop.events', { shopId: shop.guid }, { reload: true });
        };

        vm.setLocale = code => {
            $translate.use(code);
        };

        vm.setCurrency = code => {
            vm.currencies.use(code);
            $analytics.eventTrack('ecommerce', {
                'currencyCode': code
            });
        };

        vm.menuOpen = false;

        vm.toggleMenu = function() {
            if(vm.menuOpen){
                document.getElementById("header-menu-nav").style.left = '-100%';
                vm.menuOpen = false;
            }
            else{
                document.getElementById("header-menu-nav").style.left = '0';
                vm.menuOpen = true;
            }
        };
    }).name;
