import template from './shuttle.html';
import styling from './shuttle.less';

export default angular.module('eventix.shop.shuttle', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.shuttle', {
            url: '/shuttle?eventId',
            views: {
                'shop': {
                    controller: 'shuttleController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('shuttleController', function(UIMessages, shop, order, collapses, $state,
        $scope, $q, $http, $translate, Locales, Currencies, Ticket, $interval, $sce,
        $analytics, FB, $timeout, store, $filter, $location, ShopAnalytics, $uibModal) {
        var vm = this;
        vm.order = order;
        vm.messages = UIMessages;
        vm.locales = Locales;
        vm.currencies = Currencies;
        vm.shop = shop;

        // filtered
        vm.filterdTickets = {};
        vm.tickets = _.each(order.tickets, function(tickets, eventId){
            vm.filterdTickets[eventId] = _.filter(tickets, function(ticket){
                    return (ticket.name.toLowerCase()).includes('shuttle');
                });
            });

        vm.events = order.events;
        vm.products = order.products;
        vm.collapses = collapses;
        vm.eventFilter = $state.params.eventId;

        let queryParams = $location.search();
        if (queryParams.coupon)
            vm.order.applyCoupon(queryParams.coupon);

        /** Iterate over events/tickets and check availability */
        vm.availableTickets = order.recalculateAvailableTickets();
        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));

        if(vm.fourStepWizard && !vm.eventFilter)
            $state.go('eventix.shop.events', { shopId: vm.shop.guid });

        if(vm.fourStepWizard && vm.eventFilter)
            ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
        else if(!vm.fourStepWizard)
            ShopAnalytics.impression();

        vm.openCo2Modal = () => {
            $uibModal.open({
                template: `
                    <div class="co2-modal">
                        <div class="co2-modal__title">
                            <h2>What happens with this donation?</h2>
                        </div>
                        <div class="co2-modal__body">
                            These generic amounts have been calculated via <a href="https://www.atmosfair.de/en/" target="_blank">Atmosfair</a>, a non-profit organization that invests in CO₂ compensation through various renewable energy projects in more than 15 countries worldwide. Your donation will be used to start and extend such projects in the global south, such as rural electrification with solar power and mini-grids in Madagascar, small biogas plants for farmers in Nepal or efficient cookstoves in Rwanda. Read more about their projects <a href="https://www.atmosfair.de/en/climate-protection-projects/" target="_blank">here</a>, and find out more about why we compensate CO₂ via this organization <a href="https://dekmantelselectors.com/pages/co2-compensation" target="_blank">here</a>.
                        </div>
                    </div>
                `
            })
        }

        vm.back = () => {
            if(!vm.fourStepWizard)
                return $state.go('eventix.shop.tickets', { shopId: shop.guid });
            const eventId = _.findKey(order.tickets, tickets => {
                return _.some(tickets, { guid: order.firstReservation.ticket.guid });
            });
            return $state.go('eventix.shop.tickets', { shopId: shop.guid, eventId });
        };

        /** Goto next page in shop if user has selected 1+ ticket */
        vm.next = function() {
            // if (_.flatten(_.values(order.reservations)).length < 1)
            //     UIMessages.push('common.shop.order.empty');
            // else
                $state.go('eventix.shop.camping', { shopId: shop.guid });
        };

        vm.reserve = function(ticketGuid, eventId){
            vm.order.reserveTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.addToCart(ticket);
        };

        vm.release = function(ticketGuid, eventId){
            vm.order.releaseTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.removeFromCart(ticket);
        };
    }).name;
